header {
    position: fixed;
    z-index: 5;
    height: 80px;
    width: 100%;
}

nav {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

nav a {
    text-decoration: none;
    color: white;
    font-size: 1.4em;
    margin: 0 20px;
}
