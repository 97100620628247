.logo {
    position: fixed;
    z-index: 6;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80vw;
    max-height: 80vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
}
.logo svg {
    filter: drop-shadow(2px 2px 15px black);
}
.logo svg path {
    fill: white;
}
