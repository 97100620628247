.album {
    display: flex;
    flex-direction: row;
    margin: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
}
.album .image {
    max-height: 300px;
    max-width: 300px;
    width: 100%;
    overflow: hidden;
}
.album .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.album .infos {
    flex: 0 1 400px;
}
.album .infos .title {
    margin-bottom: 15px;
}
.album .infos .title .name {
    font-weight: bold;
    font-size: 1.2em;
}
.album .infos .title .date {
    font-size: .8em;
}
