.content {
    color: white;
    min-height: calc(100vh - 120px);
    max-width: 980px;
    margin: 20vh auto;
    padding-bottom: 50px;
    text-shadow: 1px 1px 2px black;
}

section {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    padding: 20px;
    margin: 10px 0;
}

.about {
    text-align: justify;
}
