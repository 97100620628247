html, body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
}
body {
  background: url(../assets/Tableau_HD_mini.jpg) no-repeat fixed;
  background-position: center;
  background-size: cover;
}

*::-webkit-scrollbar-thumb { background-color: #540000; }
*::-webkit-scrollbar { background-color: black; width: 10px; }

.App {
  position: relative;
}

a {
  text-decoration: none;
  color: beige;
  transition: .2s;
}
a:hover {
  color: #9f8d75;
}
